import axios from "axios";
import router from "./router";
import  Element from 'element-ui'

//配置拦截器

// axios.defaults.baseURL = "http://localhost:8585";

const request = axios.create({
    timeout:5000,
    headers:{
        'Content-Type':"application/json; chaarset=utf-8"
    }
})
//发起请求拦截，拦截之后附上身份
// request.interceptors.request.use(config => {
//     config.headers['Authorization'] = localStorage.getItem("token") // 请求头带上token
//     return config
//  })
 request.interceptors.response.use(response => {
       let res = response.data;
       console.log("response")
       console.log(res)
       console.log(res.code)
       if (res.code == 200) {
          return response
       } else {
         //  Element.Message.error(res.msg? res.msg : '系统异常！', {duration: 3 * 1000})
          return Promise.reject(response.data.msg)
       }
    },
    error => {
       console.log(error)
       if(error.response.data) {
          error.message = error.response.data.msg
       }
       if(error.response.status === 401) {
          router.push("/login")
       }
       Element.Message.error(error.message, {duration: 3 * 1000})
       return Promise.reject(error)
    }
 )
 export default request
