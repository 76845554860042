import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue' 
// import { component } from 'vue/types/umd'

// import store from "../store"
// import { component } from 'vue/types/umd'
Vue.use(VueRouter)
// //避免路由冗余
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location){
//   return originalPush.call(this,location).catch(err=>err)
// }

const routes = [
  {
    path: '/login',
    name: "Login",
    component: LoginView
  },
  {
    path: '/register',
    name: "Register",
    component: RegisterView
  },
  {
    path: "/forgetpwd",
    component: () => import("@/views/ForgetPwd.vue")
  },
  // {
  //   path:"*",
  //   name:"404",
  //   component:()=>import("@/views/404View.vue")
  // }
  {
    path:"/404",
    name:"404",
    component:()=>import("@/views/404View.vue")
  }
]

const router = new VueRouter({
  // mode: 'history', 
  // base: process.env.BASE_URL,
  routes
})
// 提供一个重置路由的方法
export const resetRouter=()=>{
  router.matcher=new VueRouter({
     mode: 'history', 
  base: process.env.BASE_URL,
  routes
  })
}

// 刷新页面会导致路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");
  if (storeMenus) {
    
    const currentRouterNames=router.getRoutes().map(v=>v.name)
    if(!currentRouterNames.includes('Manage')){
       //拼装动态路由
    const manageRoute = {
      path: "/",name:"home", component: ()=>import("../views/HomeView.vue"), redirect: "/index", children: [ 
        { path: "/index",name:"Index", component: ()=>import("../views/IndexView.vue")},
        { path: "/person",name:"个人信息", component: ()=>import("../views/PersonView.vue")}
       ]
    }
    const menus = JSON.parse(storeMenus);
    menus.forEach(item => {
      if (item.path) {

        //当且仅当path不为空的时候才设置路由
        let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import("../views/" + item.component + ".vue") }
        manageRoute.children.push(itemMenu)

      } else if (item.children.length) {

        item.children.forEach(i => {
          let itemMenu = { path: i.path.replace("/", ""), name: i.name, component: () => import("../views/" + i.component + ".vue") }
          manageRoute.children.push(itemMenu)
        })

      }

    }) 
      //动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }
  }
}
setRoutes(); 
router.beforeEach((to, from, next) => {
  // localStorage.setItem("currentPathName".to.name)
  // store.commit("setPath")
const storeMenus=localStorage.getItem("menus");
if(!to.matched.length){
  //未找到路由
    if(storeMenus){
    next("/404")
  }else{
    next("/login")
  }
}

next()
})
export default router
