
const Mock = require('mockjs')

const Random = Mock.Random

let Result = {
	code: 200,
	msg: '操作成功',
	data: null
}
// 物业管理 --报修管理 --获取全部报修结果
Mock.mock('/property/repair/getAll', 'get', () => {
	Result.data = [
		{
			id: 1,
			name: 'wyd',
			phone: '2880',
			address: '常熟理工学院',
			type: '入室报修',
			picture: 'https://inews.gtimg.com/om_bt/OjPq2cnMN_-ivDKjxpCZ2kk_ab8YC5VMnL-0pQ21fUvd4AA/1000',
			detail: '紧急处理，尽快',
			date: '2022-11-1',
			time: '18:00',
			status: 0
		},
		{
			id: 2,
			name: 'wyd',
			phone: '2880',
			address: '常熟理工学院',
			type: '入室报修',
			picture: '',
			detail: '紧急处理，尽快',
			date: '2022-11-1',
			time: '18:00',
			status: 1
		}, {
			id: 3,
			name: 'wyd',
			phone: '2880',
			address: '常熟理工学院',
			type: '入室报修',
			picture: '',
			detail: '紧急处理，尽快',
			date: '2022-11-1',
			time: '18:00',
			status: 2
		}
	]
	return Result;
})

//业主管理获取所有房产信息
Mock.mock('/ower/getHouseInf', 'get', () => {
	let source = [
		{
			id: 1,
			address: '111',
			zhuang: 5,
			unit: 2,
			roomId: 123,
			owner: '王先生',
			phone: '123',
			sex: 0,
			rightPerson: '王先生',
			houseId: 11,
			area: 97.5,
			shengcheng: 0,
			renzheng: 0
		},
		{
			id: 2,
			address: '111',
			zhuang: 5,
			unit: 2,
			roomId: 123,
			owner: '王先生',
			phone: '123',
			sex: 0,
			rightPerson: '王先生',
			houseId: 11,
			area: 97.5,
			shengcheng: 0,
			renzheng: 0
		},
		{
			id: 3,
			address: '111',
			zhuang: 5,
			unit: 2,
			roomId: 123,
			owner: '王先生',
			phone: '123',
			sex: 0,
			rightPerson: '王先生',
			houseId: 11,
			area: 97.5,
			shengcheng: 0,
			renzheng: 0
		},
	]
	Result.data = {
		tableData: source
	}
	return Result;
})

//业主管理房产编辑信息
Mock.mock(RegExp('/ower/editHouseInf*'), 'get', () => {
	// console.log(options)
	Result.data = {
		id: 1,
		address: '111',
		zhuang: 5,
		unit: 2,
		roomId: 123,
		owner: '王先生',
		phone: '123',
		sex: 0,
		rightPerson: '王先生',
		houseId: 11,
		area: 97.5,
		shengcheng: 0,
		renzheng: 0
	}
	return Result
})

Mock.mock('/captcha', 'get', () => {

	Result.data = {
		token: Random.string(32),
		captchaImg: Random.dataImage('120x40', 'p7n5w')
	}
	return Result
})

Mock.mock('/login', 'post', () => {

	// 无法在header中传入数jwt

	// Result.code = 400
	// Result.msg = "验证码错误"

	return Result
})
Mock.mock('/sys/userInfo', 'get', () => {

	Result.data = {
		id: "1",
		username: "admin",
		avatar: "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg"
	}

	return Result
})

Mock.mock('/logout', 'post', () => {

	return Result
})

// 获取用户菜单以及权限接口
Mock.mock('/sys/menu/nav', 'get', () => {

	let nav = [
		{
			name: 'home',
			title: '系统主页',
			icon: 'el-icon-s-operation',
			component: '',
			path: '',
			children: []
		},
		{
			name: 'managerCommunity',
			title: '小区管理',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'sysManage',
			title: '系统管理',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: [
				{
					name: 'userManage',
					title: '用户信息',
					icon: 'el-icon-s-order',
					path: '/user',
					component: 'UserView',
					children: []
				},
				{

					name: 'adminManage',
					title: '管理员管理',
					icon: 'el-icon-s-operation',
					component: 'AdminView',
					path: '/adminmanage',
					children: []

				},
				{
					name: 'roleManage',
					title: '角色管理',
					icon: 'el-icon-s-operation',
					component: 'RoleManageView',
					path: '/myrole',
					children: []
				},
				{
					name: 'menuManage',
					title: '菜单管理',
					icon: 'el-icon-s-operation',
					component: 'MenuView',
					path: '/menu',
					children: []

				},
				{

					name: 'HouseowerManage',
					title: '业主管理',
					icon: 'el-icon-s-operation',
					component: 'HouseManage',
					path: '/housemange',
					children: []

				}
			]
		}, 
		{
			name: 'houseManage',
			title: '房产管理',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'meetings',
			title: '会议',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'archive',
			title: '档案管理',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'repair',
			title: '物业维修',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'mailbox',
			title: '信箱',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []
		},
		{
			name: 'wenjuan',
			title: '问卷',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: [
				{
					name: 'repair',
					title: '问卷中心',
					icon: 'el-icon-s-order',
					path: '/questionnaire',
					component: 'QuestionnaireView',
					children: []
				},
				{
					name: 'design',
					title: '问卷设计',
					icon: 'el-icon-s-order',
					path: '/createQuestionnaire',
					component: 'CreateQuestionnaire',
					children: []
				}
			],

		},
		{
			name: 'infos',
			title: '通知新闻',
			icon: 'el-icon-s-tools',
			path: '',
			component: '',
			children: []

		}
	]

	let authoritys = ['sys:user:list', "sys:user:save", "sys:user:delete"]

	Result.data = {
		nav: nav,
		authoritys: authoritys
	}

	return Result
})

// //////////////// 菜单管理 ////////////////

Mock.mock('/edit', 'get', () => {
	Result.data = {
		name: 'wyd',
		communityName: '小区2',
		phone: '123456'
	}
	return Result;

})


Mock.mock('/edit', 'get', () => {
	Result.data = {
		name: 'wyd',
		communityName: '小区2',
		phone: '123456'
	}
	return Result;

})
//加载所有数据
Mock.mock('/data', 'get', () => {

	let tableData = [
		{
			id: 1,
			name: 'wyd1',
			communityName: '小区1',
			phone: '12346'
		},
		{
			id: 2,
			name: 'wyd2',
			communityName: '小区2',
			phone: '12346'
		},
		{
			id: 3,
			name: 'wyd3',
			communityName: '小区3',
			phone: '12346'
		},
		{
			id: 4,
			name: 'wyd4',
			communityName: '小区4',
			phone: '12346'
		}
	]
	Result.data = {
		tableData: tableData,
		total: 4
	}
	return Result;

})

Mock.mock('/sys/menu/list', 'get', () => {
	let menus = [
		{
			"id": 1,
			"created": "2021-01-15T18:58:18",
			"updated": "2021-01-15T18:58:20",
			"statu": 1,
			"parentId": 0,
			"name": "系统管理",
			"path": "",
			"perms": "sys:manage",
			"component": "",
			"type": 0,
			"icon": "el-icon-s-operation",
			"ordernum": 1,
			"children": [
				{
					"id": 2,
					"created": "2021-01-15T19:03:45",
					"updated": "2021-01-15T19:03:48",
					"statu": 1,
					"parentId": 1,
					"name": "用户管理",
					"path": "/sys/users",
					"perms": "sys:user:list",
					"component": "sys/User",
					"type": 1,
					"icon": "el-icon-s-custom",
					"ordernum": 1,
					"children": [
						{
							"id": 9,
							"created": "2021-01-17T21:48:32",
							"updated": null,
							"statu": 1,
							"parentId": 2,
							"name": "添加用户",
							"path": null,
							"perms": "sys:user:save",
							"component": null,
							"type": 2,
							"icon": null,
							"ordernum": 1,
							"children": []
						},
						{
							"id": 10,
							"created": "2021-01-17T21:49:03",
							"updated": "2021-01-17T21:53:04",
							"statu": 1,
							"parentId": 2,
							"name": "修改用户",
							"path": null,
							"perms": "sys:user:update",
							"component": null,
							"type": 2,
							"icon": null,
							"ordernum": 2,
							"children": []
						},
						{
							"id": 11,
							"created": "2021-01-17T21:49:21",
							"updated": null,
							"statu": 1,
							"parentId": 2,
							"name": "删除用户",
							"path": null,
							"perms": "sys:user:delete",
							"component": null,
							"type": 2,
							"icon": null,
							"ordernum": 3,
							"children": []
						},
						{
							"id": 12,
							"created": "2021-01-17T21:49:58",
							"updated": null,
							"statu": 1,
							"parentId": 2,
							"name": "分配角色",
							"path": null,
							"perms": "sys:user:role",
							"component": null,
							"type": 2,
							"icon": null,
							"ordernum": 4,
							"children": []
						},
						{
							"id": 13,
							"created": "2021-01-17T21:50:36",
							"updated": null,
							"statu": 1,
							"parentId": 2,
							"name": "重置密码",
							"path": null,
							"perms": "sys:user:repass",
							"component": null,
							"type": 2,
							"icon": null,
							"ordernum": 5,
							"children": []
						}
					]
				},
				{
					"id": 3,
					"created": "2021-01-15T19:03:45",
					"updated": "2021-01-15T19:03:48",
					"statu": 1,
					"parentId": 1,
					"name": "角色管理",
					"path": "/sys/roles",
					"perms": "sys:role:list",
					"component": "sys/Role",
					"type": 1,
					"icon": "el-icon-rank",
					"ordernum": 2,
					"children": []
				},

			]
		},
		{
			"id": 5,
			"created": "2021-01-15T19:06:11",
			"updated": null,
			"statu": 1,
			"parentId": 0,
			"name": "系统工具",
			"path": "",
			"perms": "sys:tools",
			"component": null,
			"type": 0,
			"icon": "el-icon-s-tools",
			"ordernum": 2,
			"children": [
				{
					"id": 6,
					"created": "2021-01-15T19:07:18",
					"updated": "2021-01-18T16:32:13",
					"statu": 1,
					"parentId": 5,
					"name": "数字字典",
					"path": "/sys/dicts",
					"perms": "sys:dict:list",
					"component": "sys/Dict",
					"type": 1,
					"icon": "el-icon-s-order",
					"ordernum": 1,
					"children": []
				}
			]
		}
	]

	Result.data = menus

	return Result
})

Mock.mock(RegExp('/sys/menu/info/*'), 'get', () => {

	Result.data = {
		"id": 3,
		"statu": 1,
		"parentId": 1,
		"name": "角色管理",
		"path": "/sys/roles",
		"perms": "sys:role:list",
		"component": "sys/Role",
		"type": 1,
		"icon": "el-icon-rank",
		"orderNum": 2,
		"children": []
	}

	return Result
})


Mock.mock(RegExp('/sys/menu/*'), 'post', () => {

	return Result
})

//////////////// 角色管理 ////////////////

Mock.mock(RegExp('/sys/role/list*'), 'get', () => {

	Result.data = {
		"records": [
			{
				"id": 3,
				"created": "2021-01-04T10:09:14",
				"updated": "2021-01-30T08:19:52",
				"statu": 1,
				"name": "普通用户",
				"code": "normal",
				"remark": "只有基本查看功能",
				"menuIds": []
			},
			{
				"id": 6,
				"created": "2021-01-16T13:29:03",
				"updated": "2021-01-17T15:50:45",
				"statu": 1,
				"name": "超级管理员",
				"code": "admin",
				"remark": "系统默认最高权限，不可以编辑和任意修改",
				"menuIds": []
			}
		],
		"total": 2,
		"size": 10,
		"current": 1,
		"orders": [],
		"optimizeCountSql": true,
		"hitCount": false,
		"countId": null,
		"maxLimit": null,
		"searchCount": true,
		"pages": 1
	}

	return Result

})

Mock.mock(RegExp('/sys/role/info/*'), 'get', () => {

	Result.data = {
		"id": 6,
		"created": "2021-01-16T13:29:03",
		"updated": "2021-01-17T15:50:45",
		"statu": 1,
		"name": "超级管理员",
		"code": "admin",
		"remark": "系统默认最高权限，不可以编辑和任意修改",
		"menuIds": [3]
	}

	return Result
})

Mock.mock(RegExp('/sys/role/*'), 'post', () => {

	return Result
})

//////////////// 用户管理 ////////////////

Mock.mock(RegExp('/sys/user/list*'), 'get', () => {
	Result.data = {
		"records": [
			{
				"id": 1,
				"created": "2021-01-12T22:13:53",
				"updated": "2021-01-16T16:57:32",
				"statu": 1,
				"username": "某某小区用户1",
				"password": "$2a$10$R7zegeWzOXPw871CmNuJ6upC0v8D373GuLuTw8jn6NET4BkPRZfgK",
				"gender": "男",
				"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
				"phone": "13291111111",
				"city": "广州",
				"lastLogin": "2020-12-30T08:38:37",
				"roles": [
					{
						"id": 6,
						"created": "2021-01-16T13:29:03",
						"updated": "2021-01-17T15:50:45",
						"statu": 1,
						"name": "超级管理员",
						"code": "admin",
						"remark": "系统默认最高权限，不可以编辑和任意修改",
						"menuIds": []
					},
					{
						"id": 3,
						"created": "2021-01-04T10:09:14",
						"updated": "2021-01-30T08:19:52",
						"statu": 1,
						"name": "普通用户",
						"code": "normal",
						"remark": "只有基本查看功能",
						"menuIds": []
					}
				]
			},
			{
				"id": 2,
				"created": "2021-01-30T08:20:22",
				"updated": "2021-01-30T08:55:57",
				"statu": 1,
				"username": "某某小区用户2",
				"password": "$2a$10$0ilP4ZD1kLugYwLCs4pmb.ZT9cFqzOZTNaMiHxrBnVIQUGUwEvBIO",
				"gender": "男",
				"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
				"phone": "13291111111",
				"city": null,
				"lastLogin": null,
				"roles": [
					{
						"id": 3,
						"created": "2021-01-04T10:09:14",
						"updated": "2021-01-30T08:19:52",
						"statu": 1,
						"name": "普通用户",
						"code": "normal",
						"remark": "只有基本查看功能",
						"menuIds": []
					}
				]
			},
			{
				"id": 3,
				"created": "2021-01-30T08:20:22",
				"updated": "2021-01-30T08:55:57",
				"statu": 1,
				"username": "某某小区用户2",
				"password": "$2a$10$0ilP4ZD1kLugYwLCs4pmb.ZT9cFqzOZTNaMiHxrBnVIQUGUwEvBIO",
				"gender": "男",
				"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
				"phone": "13291111111",
				"city": null,
				"lastLogin": null,
				"roles": [
					{
						"id": 3,
						"created": "2021-01-04T10:09:14",
						"updated": "2021-01-30T08:19:52",
						"statu": 1,
						"name": "普通用户",
						"code": "normal",
						"remark": "只有基本查看功能",
						"menuIds": []
					}
				]
			},
			{
				"id": 4,
				"created": "2021-01-30T08:20:22",
				"updated": "2021-01-30T08:55:57",
				"statu": 1,
				"username": "某某小区用户2",
				"password": "$2a$10$0ilP4ZD1kLugYwLCs4pmb.ZT9cFqzOZTNaMiHxrBnVIQUGUwEvBIO",
				"gender": "男",
				"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
				"phone": "13291111111",
				"city": null,
				"lastLogin": null,
				"roles": [
					{
						"id": 3,
						"created": "2021-01-04T10:09:14",
						"updated": "2021-01-30T08:19:52",
						"statu": 1,
						"name": "普通用户",
						"code": "normal",
						"remark": "只有基本查看功能",
						"menuIds": []
					}
				]
			},
			{
				"id": 5,
				"created": "2021-01-30T08:20:22",
				"updated": "2021-01-30T08:55:57",
				"statu": 1,
				"username": "某某小区用户2",
				"password": "$2a$10$0ilP4ZD1kLugYwLCs4pmb.ZT9cFqzOZTNaMiHxrBnVIQUGUwEvBIO",
				"gender": "男",
				"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
				"phone": "13291111111",
				"city": null,
				"lastLogin": null,
				"roles": [
					{
						"id": 3,
						"created": "2021-01-04T10:09:14",
						"updated": "2021-01-30T08:19:52",
						"statu": 1,
						"name": "普通用户",
						"code": "normal",
						"remark": "只有基本查看功能",
						"menuIds": []
					}
				]
			}
		],
		"total": 2,
		"size": 10,
		"current": 1,
		"orders": [],
		"optimizeCountSql": true,
		"hitCount": false,
		"countId": null,
		"maxLimit": null,
		"searchCount": true,
		"pages": 1
	}

	return Result
})


Mock.mock(RegExp('/sys/user/*'), 'post', () => {
	return Result
})

Mock.mock(RegExp('/sys/user/info/*'), 'get', () => {

	Result.data = {
		"id": 2,
		"created": "2021-01-30T08:20:22",
		"updated": "2021-01-30T08:55:57",
		"statu": 1,
		"username": "test",
		"password": "$2a$10$0ilP4ZD1kLugYwLCs4pmb.ZT9cFqzOZTNaMiHxrBnVIQUGUwEvBIO",
		"avatar": "https://image-1300566513.cos.ap-guangzhou.myqcloud.com/upload/images/5a9f48118166308daba8b6da7e466aab.jpg",
		"email": "test@qq.com",
		"city": null,
		"lastLogin": null,
		"roles": []
	}
	return Result
})