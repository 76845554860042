import Vue from "vue";
import Vuex from "vuex";

import menu from "./modules/menu";
import router from "@/router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    currentPathName: "",
  },
  getters: {},
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setPath(state){
      state.currentPathName=localStorage.getItem("currentPathName")
    },
    logout(){
      //清空缓存
      localStorage.removeItem("user");
      localStorage.removeItem("menus");
      router.push("/login")
      // 重置路由
      
    }
  },
  actions: {},
  modules: {
    menu,
  },
});
