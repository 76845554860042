<template>
    <div class="register-container backcontianer">
        <div class="context">
            <div class="option">
                <div class="login " @click="goToLogin">登&nbsp;&nbsp;&nbsp;&nbsp;录</div>
                <div class="register checked">注&nbsp;&nbsp;&nbsp;&nbsp;册</div>
            </div>
            <div class="value">
                <form action="">
                    <InputComponent input="phone" @inputphone-blur="handleInputBlurPhone"></InputComponent>
                    <InputComponent input="pwd" @inputpwd-blur="handleInputBlurPwd" placeholder="请输入密码">
                    </InputComponent>
                    <InputComponent input="pwd" @inputpwd-blur="handleInputBlurConformPwd" placeholder="请再次输入密码">
                    </InputComponent>
                </form>
                <div class="status">
                    <div>请选择身份：</div>
                    <select id="dropdown" v-model="identity">
                        <option value="1">平台管理员</option>
                        <option value="2">小区管理员</option>
                        <option value="3">小区物管</option>
                    </select>
                </div>
                <div class="status" v-if="identity != 1 && identity != 0">
                    <div>请选择小区：</div>
                    <select id="dropdown" @click="getInfo" v-model="community_id">
                        <option :value="item.community_id" v-for="(item, index) in communitys" :key="index">{{
                    item.community_name }}</option>
                    </select>
                </div>
                <input type="button" value="注册" @click="register">
            </div>
        </div>
    </div>
</template>

<script>
import InputComponent from '@/components/InputComponent.vue';
export default {
    data() {
        return {
            identity: 0,
            phone: "",
            pwd: "",
            confirmpwd: "",
            community_id: -1,
            communitys: [
                {
                    community_id: -1,
                    community_name: 111
                }
            ]
        }
    },
    components: {
        InputComponent
    },
    methods: {
        handleInputBlurPhone(value) {
            this.phone = value
        },
        handleInputBlurPwd(value) {
            this.pwd = value
        },
        handleInputBlurConformPwd(value) {
            this.confirmpwd = value
        },
        getInfo() {
            this.$axios.get("http://localhost:8585/community/showComName").then((res) => {
                console.log(res.data)
                this.communitys = res.data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        goToLogin() {
            this.$router.push('/login')
        },
        register() {
            if (this.pwd != this.confirmpwd) {
                this.$message({
                    showClose: true,
                    message: '两次输入密码不一致',
                    type: 'error'
                });
            } else {
                 const form = new FormData();
                form.append('phone', this.phone);
                form.append('password', this.pwd);
                form.append('identity',this.identity);
                form.append('community_id',this.community_id)
                this.$axios.post('http://localhost:8585/admin/register', form)
                    .then(res => {
                        console.log(res);
                        if (res.data.code == 200) {
                            this.$router.push('/index');
                        }
                        // const jwt = res.headers['authorization'];
                        // this.$store.commit('SET_TOKEN', jwt);
                    })
                    .catch((error) => {
                        console.log(error);
                    }); 
            } 
        }
    }
}
</script>

<style scoped>
@import "../css/common.css";

.status {
    display: flex;
    font-size: 20px;
    padding-left: 30px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
}

.status select {
    width: 300px;
    padding-left: 10px;
    font-size: 18px;
    margin-left: 10px;
}

/* .status select option{} */
</style>