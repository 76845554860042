<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default{
    watch:{
      $route(to){
        console.log(to)
        if(to.path!='/login'){
          let obj = {
            name:to.name,
            title:to.meta.title
          }
          console.log(obj)
          this.$store.commit("addTab",obj);
        }
      }
    }
  }
</script>
<style>
@import url("//at.alicdn.com/t/c/font_4515564_jj8z2pltr6.css");
#app{
  height: 100%;
}
</style>
