<template>
    <div v-if="input == 'phone'">
        <input type="text" maxlength="11" v-model="phone" @blur="checkPhone" name="" id="" placeholder="请输入手机号">
        <!-- <input type="text" v-model="uname" @blur="checkUname" name="" id="" placeholder="请输入用户名"> -->
        <p class="err">{{ errtext }}</p>
    </div>
    <div  v-else-if="input == 'pwd'">
        <input type="password" v-model="pwd" @blur="checkPwd" name="" id="" :placeholder="placeholder">
        <p class="err">{{ errPwd }}</p>
    </div>
    <div v-else-if="input == 'phoneCode'">
        <input type="text" maxlength="11" v-model="phone" @blur="checkPhone" name="" id="" placeholder="请输入手机号">
        <p class="err">{{ errtext }}</p>
        <div style="display: flex; flex-direction: row;">
            <input type="text" @blur="checkCode" v-model="code" name="" id="" placeholder="请输入验证码">
            <div class="text" :class="{ clicked: isClick, normal: !isClick }" @click="getCode">
                {{ txt }}
            </div>
        </div>
        <p class="err">{{ errCodetext }}</p>
    </div>

</template>

<script>
export default {
    props: {
        input: String,
        placeholder:String,
    },
    data() {
        return {
            uname: "",
            pwd: "123456",
            phone: "13291162819",
            code: "",

            ways: true,

            errtext: "",
            errCodetext: "",
            errUname: "",
            errPwd: "",

            isClick: false,
            txt: "获取验证码"
        }
    },

    methods: {
        checkPhone() {
            if (this.phone.length == 0) {
                this.errtext = "手机号不能为空"
            } else if (!this.phone.match(/^\d{11}$/)) {
                this.errtext = "手机号码长度不符合"
            } else if (!this.phone.match(/^1[3|5|7|8|9]\d{9}/)) {
                this.errtext = "手机号码不规范"
            } else {
                this.errtext = ""
                this.$emit('inputphone-blur', this.phone);
                this.isClick = this.txt == "获取验证码" ? true : false;
            }
        },
        checkCode() {
            if (this.code.length == 0) {
                this.errCodetext = "验证码不能为空"
            } else {
                this.errCodetext = ""
                this.$emit('inputpc-blur', this.phone+","+this.code);
            }
        },
        getCode() {
            let countdown = 10;
            if (this.isClick == true) {
                alert("111111");
                this.isClick = false;
                const interval = setInterval(() => {
                    countdown--;
                    this.txt = countdown + "s";
                    if (countdown === 0) {
                        clearInterval(interval);
                        this.txt = "获取验证码"
                        this.isClick = true;
                    }
                }, 1000);
            }
        },
        checkUname() {
            if (this.uname.length == 0) {
                this.errUname = "用户名不能为空"
            }else {
                this.errUname=""
                this.$emit('inputuname-blur', this.uname);
            }
        },
        checkPwd() {
            if (this.pwd.length == 0) {
                this.errPwd = "密码不能为空"
            }else{
                this.errPwd=""
                this.$emit('inputpwd-blur', this.pwd);
            }
        }
    },
}
</script>

<style scoped>
.text.clicked {
    background-color: rgb(116, 133, 245);
}

.text.normal {
    background-color: rgb(137, 182, 250);
}

.text {
    width: 200px;
    height: 50px;
    line-height: 50px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;
    color: white;
}
</style>