<template>
    <div class="logincontainer backcontianer">

        <div class="context">
            <div class="option">
                <div class="login checked">登&nbsp;&nbsp;&nbsp;&nbsp;录</div>
                <div class="register" @click="goTo('register')">注&nbsp;&nbsp;&nbsp;&nbsp;册</div>
            </div>
            <div class="value">
                <form action="">
                    <InputComponent input="phone" @inputphone-blur="handleInputBlurPhone"></InputComponent>
                    <InputComponent input="pwd" placeholder="请输入密码" @inputpwd-blur="handleInputBlurPwd">
                    </InputComponent>
                </form>
                <div class="other" style="justify-content: space-between;">
                    <label class="remember">
                        <input v-model="checked" type="checkbox" name="" value="1" id="">
                        记住我
                    </label>
                    <router-link class="forget" to='forgetPwd'>忘记密码</router-link>
                </div>
                <input type="button" value="登录" @click="login">
            </div>
        </div>
    </div>
</template>

<script>
import InputComponent from '@/components/InputComponent.vue'
import { setRoutes } from '@/router'
export default {
    data() {
        return {
            pwd: "123456",
            phone: "13291162819",
            checked: ""
        }
    },
    components: {
        InputComponent
    },
    methods: {
        handleInputBlurPhone(value) {
            this.phone = value
        },
        handleInputBlurPwd(value) {
            this.pwd = value
        },
        goTo(value) {
            this.$router.push("/" + value)
        },
        login() {
            console.log(this.phone, this.pwd);
            const form = new FormData();
            form.append('phone', this.phone);
            form.append('password', this.pwd);
            form.append('remember', this.checked);

            this.$axios.post('http://47.122.23.164:8585/admin/login', form)
                .then(res => {
                    console.log(res);
                    const user=res.data.data;
                    console.log(user)
                    console.log(user.menus)
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('menus',JSON.stringify(user.menus));

                    setRoutes();

                    if (res.data.code == 200) {
                        this.$router.push('/');
                    }
                    // const jwt = res.headers['authorization'];
                    // this.$store.commit('SET_TOKEN', jwt);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
}
</script>

<style scoped>
@import "../css/common.css";
</style>